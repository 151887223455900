$(document).ready(function ()
{


	/*
     *    IE 11 SNIFFER
     */

     var isIE11 = !!navigator.userAgent.match(/Trident.*rv\:11\./);
	    if (isIE11) {
	        $('body').addClass('ie11');
	    }

	/*
     *    IE 10 SNIFFER
     */
    if (navigator.appVersion.indexOf("MSIE 10") !== -1) {
        $('body').addClass('ie10');
    }


	// Youtube/Vimeo thumbs
	$("[class*='rc-video'] .thumb").click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			iframe.attr('src', src + '?autoplay=1');
		}
		block.fadeOut(150);
	}


	// Lightbox - UNCOMMENT ME BIJ KOPPELEN

	$('a.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true
	});

	var count = 20;
	$('.easyload-row .row .reference-img').hide();
	$('.easyload-row .row .reference-img:lt('+count+')').show();

	$('#easyload-button').click(function(){
		count += 8;
		$('.easyload-row .row .reference-img:lt('+count+')').fadeIn();

		if(count >= $('.easyload-row .row .reference-img').size()){
			$(this).hide();
		}
	});



	// Portfolio Carousel

	var carousel = $(".portfolioslider");
	carousel.owlCarousel({
    navigation:true,
	loop:true,
    margin:0,
	nav: true,
  	navText: ["<",">"],
  	responsive:{
        0:{
            items:1
        },
        544:{
        	items:3
        },
        992:{
            items:4
        }
	    },
    	    navigationText: [
	      "<",
	      ">"
	      ],
	  });

	  $( ".owl-prev").html('<a class="portfolioslider-arrow prev"></a>');
 	  $( ".owl-next").html('<a class="portfolioslider-arrow next"></a>');

});